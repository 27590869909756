import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PostHarvestGraph = ({
    data,
    maxPayoutActive,
    setMaxPayoutActive,
    avgPayoutActive,
    setAvgPayoutActive,
    percentilePayoutActive,
    setPercentilePayoutActive,
    priceUnit
}) => {
    const categories = data.map(item => item.year);
    const maxPayout = data.map(item => item.max_payout);
    const percentile95Payout = data.map(item => item.percentile_95_payout);
    const avgPayout = data.map(item => item.avg_payout);
    const block_count = data.map(item => item['payout_count/total_simulations']);

    const options = {
        chart: {
            type: 'column',
            height: 340
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories,
            title: {
                text: ''
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: `Payout ${priceUnit}`
            }
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal'
        },
        tooltip: {
            formatter: function () {
                // Access data point index and corresponding block_count
                const pointIndex = this.point.index;
                const blockCountValue = block_count[pointIndex];

                return `<b>${this.series.name}</b>: Rs ${this.y.toFixed(2)}<br/>
                        <b>Payout Count / Total Simulations</b>: ${blockCountValue}`;
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                events: {
                    legendItemClick: function (event) {
                        const seriesName = event.target.name;
                        if (seriesName === 'Max Payout') {
                            setMaxPayoutActive(!maxPayoutActive);
                        } else if (seriesName === '95th Percentile Payout') {
                            setPercentilePayoutActive(!percentilePayoutActive);
                        } else if (seriesName === 'Average Payout') {
                            setAvgPayoutActive(!avgPayoutActive);
                        }
                        return false; // prevents the default action
                    }
                }
            }
        },
        series: [
            {
                name: 'Max Payout',
                data: maxPayout,
                color: '#1f77b4',
                visible: maxPayoutActive
            },
            {
                name: '95th Percentile Payout',
                data: percentile95Payout,
                color: '#4682b4',
                visible: percentilePayoutActive
            },
            {
                name: 'Average Payout',
                data: avgPayout,
                color: '#87cefa',
                visible: avgPayoutActive
            }
        ],
        credits: {
            enabled: false
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PostHarvestGraph;

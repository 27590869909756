import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Auth } from "aws-amplify";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const CollapsiblePriceTable = ({ policy_number, insurancetype }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState({});
  const [movingAverageKey, setMovingAverageKey] = useState('');
  const [priceColumns, setPriceColumns] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      fetch(`/api/monitor/monitoringTable?policy_number=${policy_number}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setData(result);
          if (result.length > 0 && result[0].days.length > 0) {
            const dayKeys = Object.keys(result[0].days[0]);
            const priceKeys = dayKeys.filter(key => key !== 'date' && key !== 'avg' && key !== 'Claim Status' && !key.includes('Moving Average'));
            setPriceColumns(priceKeys);
            const dynamicKey = dayKeys.find(key => key.includes('Moving Average'));
            if (dynamicKey) setMovingAverageKey(dynamicKey);
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
    fetchData();
  }, [policy_number]);

  const handleToggle = (month) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [month]: !prevOpen[month],
    }));
  };

  const getClaimStatusStyles = (status) => {
    switch (status) {
      case 'Freeze Period':
        return { backgroundColor: '#B0BEC5', borderRadius: '8px', padding: '2px 4px', color: 'white' };
      case 'Trigger':
        return { backgroundColor: '#FF5252', borderRadius: '8px', padding: '2px 4px', color: 'white' };
      case 'No trigger':
        return { backgroundColor: '#1976D2', borderRadius: '8px', padding: '2px 4px', color: 'white' };
      default:
        return {};
    }
  };

  const filteredData = data.filter(monthData => monthData.days.length > 0);

  const downloadCSV = () => {
    const csvData = [];
    const header = ["Month", "Day", ...priceColumns, movingAverageKey, "Claim Status"];
    csvData.push(header);

    filteredData.forEach(monthData => {
      monthData.days.forEach(day => {
        const row = [
          monthData.month,
          day.date,
          ...priceColumns.map(col => day[col] ?? 'N/A'),
          day[movingAverageKey] ?? 'N/A',
          day['Claim Status'] ?? 'N/A'
        ];
        csvData.push(row);
      });
    });

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `price_table_${policy_number}.csv`);
  };

  return (
    <Paper sx={{ border: '1px solid #E0E0E0', borderRadius: '8px', width: '90%', margin: 'auto', padding: '1px' }}>
      <Button variant="contained" onClick={downloadCSV} sx={{ margin: '10px',float:"right" }}>
        Download CSV
      </Button>
      <TableContainer sx={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F2F2F0", borderBottom: '2px solid #E0E0E0' }}>
              <TableCell align="center" sx={{ padding: '10px' }}>Month</TableCell>
              <TableCell align="center" sx={{ padding: '10px' }}>Day</TableCell>
              {priceColumns.map((col) => (
                <TableCell key={col} align="center" sx={{ padding: '10px' }}>{col}</TableCell>
              ))}
              {!insurancetype.includes('Sowing') && <TableCell align="center" sx={{ padding: '10px' }}>{movingAverageKey}</TableCell>}
              {!insurancetype.includes('Sowing') && <TableCell align="center" sx={{ padding: '10px' }}>Claim Status</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((monthData, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell colSpan={insurancetype.includes('Sowing') ? 3 + priceColumns.length : 4 + priceColumns.length}>
                    <IconButton onClick={() => handleToggle(monthData.month)}>
                      {open[monthData.month] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                    {monthData.month}
                  </TableCell>
                </TableRow>
                {open[monthData.month] && monthData.days.map((day, idx) => (
                  <TableRow key={idx} sx={{ borderBottom: '1px solid #E0E0E0' }}>
                    {idx === 0 && (
                      <TableCell rowSpan={monthData.days.length} align="center" sx={{ backgroundColor: '#E3F2FD', fontWeight: 'bold', padding: '10px' }}>
                        {monthData.month}
                      </TableCell>
                    )}
                    <TableCell align="center" sx={{ padding: '10px' }}>{day.date}</TableCell>
                    {priceColumns.map((col) => (
                      <TableCell key={col} align="center" sx={{ padding: '10px' }}>{day[col] ?? 'N/A'}</TableCell>
                    ))}
                    {!insurancetype.includes('Sowing') && <TableCell align="center" sx={{ padding: '10px' }}>{day[movingAverageKey] ?? 'N/A'}</TableCell>}
                    {!insurancetype.includes('Sowing') && (
                      <TableCell align="center" sx={{ padding: '10px' }}>
                        <span style={getClaimStatusStyles(day['Claim Status'])}>
                          {day['Claim Status'] ? day['Claim Status'] : 'N/A'}
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CollapsiblePriceTable;
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsmobile from "./aws-exports";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import Pricing from "./components/Pricing/Pricing";
import { Dashboard } from "./components/Monitor/Dashboard";
import PolicyCreation from "./components/Monitor/PolicyCreation";
import Explore from "./components/Explore/Explore";
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Monitor } from "./components/Monitor/Monitor";

Amplify.configure(awsmobile);

function App(props) {
  const [menu, setMenu] = useState("");
  const [userRole, setUserRole] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [selectedPolicyNo, setselectedPolicyNo] = useState("");
  const [selectedPolicymasterHolder, setselectedPolicymasterHolder] = useState("");

  const handleMenuClick = (selectedMenu) => {
    setMenu(selectedMenu);
  };


  useEffect(() => {
    async function fetchData() {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      setUserEmail(email)
      try {
        const response = await fetch(
          `/api/admin/getUserRole?user_email=${email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": jwtToken,
            },
          }
        );
        const data = await response.json();
        setUserRole(data[0].user_role)

      } catch (error) {
        console.error("Error :", error);
      }
    }
    fetchData()
  }, [])



  const ProtectedRoute = ({ userRole, allowedRoles, children }) => {
    if (!userRole) {
      // Wait for userRole to load
      return null;
    }
    return allowedRoles.includes(userRole) ? children : <Navigate to="/monitorPolicies" replace />;
  };


  return (
    <div className="App">
      <Header email={userEmail} setEmail={setUserEmail} />
      <div style={{ marginTop: "45px" }}>
        <Sidebar setMenu={handleMenuClick} menu={menu} />
      </div>
      <Routes>
        <Route path="/explore" element={<ProtectedRoute path="/explore" userRole={userRole} allowedRoles={["admin"]} ><Explore /></ProtectedRoute>} />
        <Route path="/" element={<ProtectedRoute path="/" userRole={userRole} allowedRoles={["admin"]} ><Explore /></ProtectedRoute>} />
        <Route path="/pricing" element={<ProtectedRoute path="/pricing" userRole={userRole} allowedRoles={["admin"]} ><Pricing /></ProtectedRoute>} />
        <Route path="/monitorPolicies" element={<ProtectedRoute path="/monitorPolicies" userRole={userRole} allowedRoles={["customer", "admin"]}>
          <Dashboard setMenu={handleMenuClick} menu={menu}
            selectedPolicyNo={selectedPolicyNo}
            setselectedPolicyNo={setselectedPolicyNo}
            setselectedPolicymasterHolder={setselectedPolicymasterHolder} /></ProtectedRoute>} />
        <Route path="/createPolicies" element={<ProtectedRoute path="/createPolicies"  userRole={userRole} allowedRoles={["admin"]} ><PolicyCreation setMenu={handleMenuClick} /></ProtectedRoute>} />
        <Route path="/comparePolicies" element={<ProtectedRoute path="/comparePolicies" userRole={userRole} allowedRoles={["customer", "admin"]}><Monitor setMenu={handleMenuClick}
          selectedPolicyNo={selectedPolicyNo}
          selectedPolicymasterHolder={selectedPolicymasterHolder} />
        </ProtectedRoute>} />
      </Routes>
    </div>
  );
}

export default withAuthenticator(App);

import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  CircularProgress,
} from "@mui/material";
import PriceTrend from "./PriceTrend";
import MonthlyAvgPriceGraph from "./MonthlyAvgPriceGraph";
import HeatMap from "./HeatMap";
import Select from "react-select";
import { Auth } from "aws-amplify";


const customStyles = {
  control: (provided) => ({
    ...provided,

  }),
  container: (provided) => ({
    ...provided,

  }),
};


const CompareExplore = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const [sourceOptions, setSourceOptions] = useState("Agmarknet");

  useEffect(() => {
    if (props.cropName && props.marketName) {
      props.setPriceSource([]);
      const fetchSourceNames = async () => {
        try {
          const { accessToken: { jwtToken } } = await Auth.currentSession();
          let requestBody = {
            crop_name: props.cropName,
            market_names: props.marketName,
          }
          const response = await fetch(
            `/api/explore/getSourceName`,
            {
              method: "POST",
              headers: {
                Authorization: jwtToken,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody)
            }
          );
          const data = await response.json();
          const formattedOptions = data.map((source) => ({
            label: source._id,
            value: source._id,
          }));
          setSourceOptions(formattedOptions);
        } catch (error) {
          console.error("Error fetching price sources:", error);
        }
      };

      fetchSourceNames();
    } else {
      setSourceOptions([]);
    }
    // eslint-disable-next-line
  }, [props.marketName, props.cropVariety, props.cropName]);






  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderPriceTable = () => {
    const years = Array.from(new Set(props.monthlyAveragePricesData.map((d) => d.year)));

    // Extract unique months available in the data
    const monthsWithData = Array.from(new Set(props.monthlyAveragePricesData.map((d) => d.month)));

    // Organize price data by month and year
    const priceData = {};
    props.monthlyAveragePricesData.forEach(({ month, year, price }) => {
      if (!priceData[month]) priceData[month] = {};
      priceData[month][year] = price;
    });

    return (
      <TableContainer component={Paper} sx={{ maxHeight: 420, overflowY: "auto" }}>
        <Table stickyHeader size="small" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", border: "1px solid #ddd", padding: "4px" }}>Month</TableCell>
              {years.map((year) => (
                <TableCell key={year} style={{ fontWeight: "bold", border: "1px solid #ddd", textAlign: "center", padding: "4px" }}>
                  {year}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {monthsWithData.map((month) => (
              <TableRow key={month}>
                <TableCell style={{ border: "1px solid #ddd", fontWeight: "bold", padding: "5px" }}>{month}</TableCell>
                {years.map((year) => (
                  <TableCell
                    key={`${month}-${year}`}
                    style={{ border: "1px solid #ddd", textAlign: "center", padding: "5px" }}
                  >
                    {priceData[month] && priceData[month][year] ? priceData[month][year] : '-'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };


  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Generate Tabs"
      >
        <Tab label="Data Validation" sx={{ textTransform: "capitalize", fontSize: "16px" }} />
        <Tab label="Risk Analytics" sx={{ textTransform: "capitalize", fontSize: "16px" }} />
        <Tab label="Outlook" sx={{ textTransform: "capitalize", fontSize: "16px" }} />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {tabValue === 0 && (
          <>
            {props.showCompare ? (
              <Box>
                {props.loading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <>
                    {props.heatMapData ? (
                      <>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "left",
                            marginBottom: "20px",
                          }}
                        >
                          Percentage share of good data days
                        </div>
                        <HeatMap data={props.heatMapData} variety={props.cropVariety} />
                      </>
                    ) : (
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: "30px",
                        justifyContent: "center",
                      }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                    {props.priceTrendData ?
                      (<PriceTrend
                        data={props.priceTrendData}
                        priceSource={props.priceSource}
                        marketName={props.marketName}
                        cropName={props.cropName}
                        startYear={props.policyStartYear}
                        endYear={props.policyEndYear}
                        startMonth={props.monitoringStartMonth}
                        endMonth={props.monitoringEndMonth} />) : ('')}
                  </>
                )}
              </Box>
            ) : (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  height: "90vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: 'url("Agtuall_compare_map.svg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1,
                }}
              >
                <div className="selectmodule">
                  Fill the Input Fields from the left panel to start exploring Data & Charts
                </div>
              </div>
            )}
          </>
        )}
        {tabValue === 1 && (
          <>
            {props.showCompare ? (
              <div>
                <div style={{ float: 'right' }}>
                  <h2 className="select-label" >
                    Price Source
                  </h2>
                  <Select
                    className="custom-select"
                    placeholder="Price Source"
                    styles={customStyles}
                    value={
                      props.priceSource ? { label: props.priceSource, value: props.priceSource } : null
                    }
                    onChange={(selectedOption) => {
                      props.setPriceSource(selectedOption.value);
                      props.setPriceSource(selectedOption.value);
                    }
                    }
                    options={sourceOptions}
                  />
                </div>
                <div style={{ fontSize: "16px", fontWeight: 500, textAlign: "left", marginBottom: "20px", marginTop: "10px" }}>
                  Average Monthly Prices ({props.policyStartYear}-{props.policyEndYear})
                </div>
                {props.monthlyAvgVsArrivalData != null && (
                  <MonthlyAvgPriceGraph data={props.monthlyAvgVsArrivalData} />
                )}
                {/* Render the compact, scrollable price table */}
                {props.monthlyAveragePricesData.length > 0 && renderPriceTable()}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  height: "90vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: 'url("Agtuall_compare_map.svg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1,
                }}
              >
                <div className="selectmodule">
                  Fill the Input Fields from the left panel to start exploring Data & Charts
                </div>
              </div>
            )}
          </>
        )}
        {tabValue === 2 && <Typography>Outlook</Typography>}
      </Box>
    </Box>
  );
};

export default CompareExplore;
